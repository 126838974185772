.fullScreen {
    width: 100vw;
    height: 100vh;
    overflow: hidden!important;
}

.ViewerFullScreen {
    width: 100vw;
    height: 92vh;
    height: var( — app-height) * 0.92;
    overflow: hidden!important;
}

.canvasDivClass {
    width: 80vw;
    height: 92vh;
    height: var( — app-height) * 0.92;
    border-style: solid;
    border-width: 5px;
}

.followUserBtn {
    position: absolute;
    bottom: 4vh;
    left: 4vh;
    background: none;
    border: none; 
    height: min(8vh, 40px); 
    padding: 0;
    color: #311d6e;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}