/* @import '~mdb-ui-kit/css/mdb.min.css'; */

.gradient-custom {
    /* fallback for old browsers */
    background: #6a11cb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    height: 92vh;
    width: 100vw;
}

.loginHeight {
    height: 92vh!important;
}

.loginFormHeight {
    height: min(80vh, 120vw);
    overflow: auto;
}

.loginFormTextHeight {
    height: 100%;
}

.customAlert {
    position: absolute;
    bottom: 2vh;
    right: 10vw;
    left: 10vw;
}

.customInput {
    width: 100%;
    border-width: 0.125rem !important;
}