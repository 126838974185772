.adminTimelineFullScreen {
    width: 100vw;
    height: 94vh;
}

.timelineProgress {
    height: 5px;
    -webkit-appearance: none;
    width: 80%;
    margin-bottom: 0;
    border-radius: 8px;
    background: #3b7677;
    transition: background 0.2s ease;
    cursor: pointer;
}

.adminTimelineObj {
    display: flex;
    flex-direction: column;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.playPauseBtn {
    background: none;
    border: none; 
    height: 5vh; 
    cursor: pointer;
}

.timelineOverflow {
    height:16vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    margin-bottom: 2vh;
    margin-top: 10vh;

}

.timelineOverflow::-webkit-scrollbar {
    width: 1vw;
}

/* Track */
.timelineOverflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 0.5vw;
}
   
/* Handle */
.timelineOverflow::-webkit-scrollbar-thumb {
    background: rgba(0, 94, 255, 0.5); 
    border-radius: 10px;
}
  
/* Handle on hover */
.timelineOverflow::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 94, 255, 0.8); 
}