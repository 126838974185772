.adminFullScreen {
    width: 100vw;
    height: 92vh;
    overflow: hidden!important;
    
}

.adminContentHolder {
    width: 100vw;
    height: 80vh;
    overflow: hidden!important;
}

.adminContentSideBar {
    width: 20%;
    height: 100%;
    overflow: auto!important;
}

.adminContentPage {
    width: 80%;
    height: 100%;
    overflow: auto!important;
}

.customHeader {
    font-size: min(8vw, 8vh);
    font-family: Arial Black;
}

.customAccordion {
    width: 55vw;
    margin-left: auto;
    margin-right: auto;
}

.customOverflow {
    height:75vh;
    overflow-x:hidden!important;
    
}

.customOverflow::-webkit-scrollbar {
    width: 1vw;
}
  
/* Track */
.customOverflow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 0.5vw;
}
   
/* Handle */
.customOverflow::-webkit-scrollbar-thumb {
    background: rgba(0, 94, 255, 0.5); 
    border-radius: 10px;
}
  
/* Handle on hover */
.customOverflow::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 94, 255, 0.8); 
}

.adminFullSize {
    height: 100%;
    width: 100%;
}

.adminTextAlignCenter {
    text-align: center;
}